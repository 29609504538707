<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  .custom-file-label::after {
    display: none;
  }
  .log-tips{
    margin-top: 20px;
  }
</style>
<script>
  import LoginTop from "@/views/pages/account/login-top";
  import {
    getcode,
  } from "@/api/index.js"
  export default {
    data() {
      return {
                baseConfig:{},
    // 图片验证码信息
      photoCodeInfo: {
        uuid: "",
        img: "@/assets/images/person/check.png"
      },
      };
    },
    components:{
      LoginTop
    },
    methods:{
      initPage: function () {
        var _this = this;
        getcode().then(res => {
          if (res.status) {
            _this.photoCodeInfo.uuid = res.data.uuid
            _this.photoCodeInfo.img = res.data.img
          }
        })
      },


    },
    mounted() {
      this.initPage()
    },
    created() {
      let conf=JSON.parse(window.localStorage.getItem('BaseConfig'))
      this.baseConfig=conf?conf:{}
    }
  };
</script>
<!-- <script>

  import {
    required,
    email
  } from "vuelidate/lib/validators";

  import {
    authMethods,
    authFackMethods,
    notificationMethods
  } from "@/state/helpers";

  export default {
    data() {
      return {
        email: "admin@themesdesign.in",
        password: "123456",
        submitted: false
      };
    },
    computed: {
      notification() {
        return this.$store ? this.$store.state.notification : null;
      }
    },
    created() {
      document.body.classList.add("auth-body-bg");
    },
    validations: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    },
    methods: {
      ...authMethods,
      ...authFackMethods,
      ...notificationMethods,
      // Try to log the user in with the username
      // and password they provided.
      tryToLogIn() {
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        } else {
          if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
            this.tryingToLogIn = true;
            // Reset the authError if it existed.
            this.authError = null;
            return (
              this.logIn({
                email: this.email,
                password: this.password
              })
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || {
                    name: "home"
                  }
                );
              })
              .catch(error => {
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
            );
          } else {
            const {
              email,
              password
            } = this;
            if (email && password) {
              this.login({
                email,
                password
              });
            }
          }
        }
      }
    }
  };
</script> -->

<template>
  <div>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          <LoginTop />
          <div class="login-bottom mt-4 d-flex" style="height: 690px;">
            <div class="find-password  w-100">
              <div>
                <p class="log-tips" style="text-align: left;">
                  账号赎回
                </p>
                <div class="login-span mb-2" style="text-align: left;">
                  <span>说明：如果您的账号被其他人注册或者手机号发生了变更，无法找回密码，请填写以下表格并赎回账号。</span>
                </div>
              </div>


              <form class="login-form">
                <div class="log-form-box">
                  <div class="log-label mr-3">您的姓名</div>
                  <div class="log-form-r"><input placeholder="请输入注册时姓名" class="log-form-input form-control" />
                    <span class="text-danger">（*必填）</span>
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">身份证号</div>
                  <div class="log-form-r"><input placeholder="请输入您的证件号码" class="log-form-input form-control" />
                    <span class="text-danger">（*必填）</span>
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">手机号码</div>
                  <div class="log-form-r"><input placeholder="请输入您目前在使用的手机号" class="log-form-input form-control" />
                    <span class="text-danger">（*必填）</span>
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">赎回原因</div>
                  <div class="log-form-r">
                    <div class="form-check mr-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        checked
                      />
                      <label class="form-check-label" for="exampleRadios1">身份证件信息被他人恶意注册</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option2"
                      />
                      <label class="form-check-label" for="exampleRadios2">原注册手机号已不再使用</label>
                    </div>
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">证件扫描件</div>
                  <div class="log-form-r">
                    <div class="custom-file" style="width: 45%;">
                      <input id="customFile" type="file" class="custom-file-input d-none" />
                      <label class="custom-file-label" for="customFile"><img src="@/assets/images/person/icon/upload.png"  class="mr-2">上传身份证扫描件（人像面）</label>
                    </div>
                    <span class="text-danger">（*不超过5M）</span>
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">手持证件照</div>
                  <div class="log-form-r" >
                    <div class="custom-file" style="width: 45%;">
                      <input id="customFile" type="file" class="custom-file-input" />
                      <label class="custom-file-label" for="customFile"><img src="@/assets/images/person/icon/upload.png" class="mr-2" >本人手持身份证（人像面）照）</label>
                    </div>
                    <span class="text-danger">（*需能清洗的看清楚本人全脸及身份证信息，不超过5M）</span>
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">其他说明</div>
                  <div class="log-form-r"><input placeholder="如果有其他信息需要补充请填写" class="log-form-input form-control" />
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3">验证码</div>
                  <div class="log-form-r"><input placeholder="请输入验证码" class="log-form-input form-control" style="width: 300px;" />
                    <img :src="photoCodeInfo.img" style="height:35px;cursor: pointer;" @click="initPage">
                  </div>
                </div>
                <div class="log-form-box">
                  <div class="log-label mr-3"></div>
                  <div class="log-form-r"><button type="button" class="btn btn-info mr-2"
                      style="width: 195px;" @click="checkForm">提交赎回申请</button>
                    <button type="button" class="btn btn-secondary" style="width: 110px;">取消</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="login-footer text-center mt-4">
            版权所有：{{baseConfig.WebsiteName}}
<!--            © {{baseConfig.SystemYear}} Rights Reserved-->
          </div>
        </div>



      </div>
    </div>
  </div>
</template>
